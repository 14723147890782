import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-started",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#getting-started",
        "aria-label": "getting started permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Getting started`}</h1>
    <p>{`Many students and instructors find this textbook very easy to read:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://web.stanford.edu/~jurafsky/slp3/",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Speech and Language Processing`}</a></li>
    </ul>
    <p>{`I would begin your journey by reading through that book and attempting the exercises at the end of each chapter.`}</p>
    <p>{`If any of the math is confusing, refer to the resources section below.`}</p>
    <h1 {...{
      "id": "resources-for-math",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#resources-for-math",
        "aria-label": "resources for math permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Resources for math`}</h1>
    <p>{`Everything I've listed here is freely available and largely self-paced study.`}</p>
    <h2 {...{
      "id": "machine-learning",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#machine-learning",
        "aria-label": "machine learning permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Machine learning`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.coursera.org/learn/machine-learning",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Andrew Ng's Machine learning course`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/playlist?list=PLD0F06AA0D2E8FFBA",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Overview of machine learning (mathematicalmonk)`}</a></li>
    </ul>
    <h2 {...{
      "id": "neural-networks",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#neural-networks",
        "aria-label": "neural networks permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Neural Networks`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://neuralnetworksanddeeplearning.com/",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Neural Networks and Deep Learning`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=3DaircAruvnKk&list=3DPLZHQOb=OWTQDNU6R1_67000Dx_ZCJB-3pi",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Neural Networks (3blue1brown)`}</a></li>
    </ul>
    <h2 {...{
      "id": "statistics-and-probability-theory",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#statistics-and-probability-theory",
        "aria-label": "statistics and probability theory permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Statistics and probability theory`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/playlist?list=PL17567A1A3F5DB5E4",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Overview of probability theory (mathematicalmonk)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://greenteapress.com/wp/think-bayes/",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Think Bayes`}</a></li>
    </ul>
    <h2 {...{
      "id": "linear-algebra",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#linear-algebra",
        "aria-label": "linear algebra permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Linear algebra`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=3DfNk_zzaMoSs&list=3DPLZHQOb=OWTQDPD3MizzM2xVFitgF8hE_ab",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Essence of linear algebra (3blue1brown)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.khanacademy.org/math/linear-algebra",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Linear algebra (Khan Academy)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.udacity.com/course/linear-algebra-refresher-course--ud953",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Linear algebra refresher course (Udacity)`}</a></li>
    </ul>
    <h2 {...{
      "id": "calculus",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#calculus",
        "aria-label": "calculus permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Calculus`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=3DWUvTyaaNkzM&list=3DPLZHQOb=OWTQDMsr9K-rj53DwVRMYO3t5Yr",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Essence of calculus (3blue1brown)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.khanacademy.org/math/differential-calculus",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Differential calculus (Khan Academy)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.khanacademy.org/math/multivariable-calculus",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Multivariable calculus (Khan Academy)`}</a></li>
    </ul>
    <h2 {...{
      "id": "misc",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#misc",
        "aria-label": "misc permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Misc`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.khanacademy.org/math",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Khan Academy (all math topics)`}</a></li>
      <li parentName="ul">{`[Information Theory (Khan Academy)]`}{`(`}<a parentName="li" {...{
          "href": "https://www.khanacademy.org/computing/computer-science/informatio=",
          "target": "_self",
          "rel": "nofollow"
        }}>{`https://www.khanacademy.org/computing/computer-science/informatio=`}</a>{`
ntheory#moderninfotheory)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://mml-book.github.io/",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Mathematics of Machine Learning`}</a></li>
    </ul>
    <h1 {...{
      "id": "intermediateadvanced",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#intermediateadvanced",
        "aria-label": "intermediateadvanced permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Intermediate/Advanced`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.fast.ai/2019/07/08/fastai-nlp/",
          "target": "_self",
          "rel": "nofollow"
        }}>{`A Code-First Introduction to Natural Language Processing (fast.ai)`}</a></li>
      <li parentName="ul">{`Neural Network Methods for Natural Language Processing `}
        <ul parentName="li">
          <li parentName="ul">{`Freely available via the library`}</li>
        </ul>
      </li>
    </ul>
    <h1 {...{
      "id": "resources-for-programming",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#resources-for-programming",
        "aria-label": "resources for programming permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Resources for programming`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.udacity.com/course/linux-command-line-basics--ud595",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Linux command line basics (Udacity)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.udacity.com/course/version-control-with-git--ud123",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Version control and Git (Udacity)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.udacity.com/course/introduction-to-python--ud1110",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Programming basics using Python (Udacity)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      